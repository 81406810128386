import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { Button } from "../components/ui/button";
import { PropertyPreview } from "../components/PropertyPreview";
import { ReservationForm } from "../components/ReservationForm";
import { PaymentDetails } from "../components/PaymentDetails";
import { MessageHost } from "../components/MessageHost";
import { PolicySection } from "../components/PolicySection";

export default function ConfirmReservationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dates, guests, basePrice, cleaningFee, nights, subtotal, serviceFee, total, monthlyPayment, savings } = location.state;

  const handleSubmit = async (formData: FormData) => {
    setIsSubmitting(true);
    // Simulate form submission
    setTimeout(() => {
      setIsSubmitting(false);
      navigate('/reservation-confirmed');
    }, 2000);
  };

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <Button
          variant="ghost"
          className="mb-6 flex items-center text-lg"
          onClick={() => navigate(-1)}
        >
          <ChevronLeft className="mr-2 h-5 w-5" />
          Confirm reservation
        </Button>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div>
            
            <ReservationForm onSubmit={handleSubmit} isSubmitting={isSubmitting}
            //@ts-ignore
             dates={dates} guests={guests} />
            <MessageHost />
            <PolicySection />
          </div>
          <div className="space-y-6">
            <PropertyPreview />
            <PaymentDetails 
            //@ts-ignore
              basePrice={basePrice}
              cleaningFee={cleaningFee}
              nights={nights}
              subtotal={subtotal}
              serviceFee={serviceFee}
              total={total}
              monthlyPayment={monthlyPayment}
              savings={savings}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

