import { Star } from 'lucide-react'
import { Badge } from "./ui/badge"

export default function GuestFavoriteBadge() {
  return (
    <div className="flex items-center gap-4 mt-5 w-[300px] flex-wrap md:w-[700px] p-4 rounded-md border justify-evenly">
      <div className="flex items-center">
        <Badge variant="secondary" className="flex items-center gap-1.5">
          <span className="text-lg">✦</span>
          Guest favorite
          <span className="text-lg">✦</span>
        </Badge>
      </div>

      <p className="text-sm w-[300px] font-semibold hidden md:flex ">
          One of the most loved homes on Airbnb, according to guests
        </p>
      
          <div className="flex items-center flex-col">
          <Star className="w-4 h-4 fill-current" />
          <span className="font-medium ml-1">4.9</span>
        </div>
        <div className="flex items-center flex-col">
          <span className="font-medium ml-1 text-xl">558</span>
          <span className="font-medium text-sm ml-1">Reviews</span>
        </div>
    </div>
  )
}

