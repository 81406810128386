import React from 'react';
import { StarIcon } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from './ui/button';


export function Reviews() {
  const reviews = [
    {
      author: "Sarah",
      date: "January 2024",
      rating: 5,
      content: "Amazing place! Very clean and comfortable. The location is perfect.",
      avatar: "/placeholder.svg?height=40&width=40",
    },
    {
      author: "Mike",
      date: "December 2023",
      rating: 4,
      content: "Great apartment, well equipped and in a nice neighborhood.",
      avatar: "/placeholder.svg?height=40&width=40",
    },
    {
      author: "Sarah",
      date: "November 2023",
      rating: 5,
      content: "Amazing stay! Super clean and very comfortable. Highly recommended.",
      avatar: "/placeholder.svg?height=40&width=40",
    },
    {
      author: "John",
      date: "October 2023",
      rating: 3,
      content: "Decent place but could use some updates. The location was good.",
      avatar: "/placeholder.svg?height=40&width=40",
    },
    {
      author: "Emily",
      date: "September 2023",
      rating: 4,
      content: "Lovely apartment with great amenities. Would stay again.",
      avatar: "/placeholder.svg?height=40&width=40",
    },
    {
      author: "Daniel",
      date: "August 2023",
      rating: 2,
      content: "Not as described. The neighborhood was noisy, and cleanliness could improve.",
      avatar: "/placeholder.svg?height=40&width=40",
    }
  ];

  return (
    <div className='md:w-[1200px] '>
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <StarIcon className="h-5 w-5 text-primary" />
        <span className="font-semibold">4.9</span>
        <span className="text-muted-foreground">• 128 reviews</span>
      </div>
      <div className=" md:grid  md:grid-cols-2 md:gap-6">
  {reviews.map((review, i) => (
    <div key={i} className="space-y-4 p-4 border rounded-md mb-2 md:mb-0">
      <div className="flex items-center gap-4">
        <Avatar>
          <AvatarImage src={review.avatar} alt={review.author} />
          <AvatarFallback>{review.author[0]}</AvatarFallback>
        </Avatar>
        <div>
          <div className="font-semibold">{review.author}</div>
          <div className="text-sm text-muted-foreground">{review.date}</div>
        </div>
      </div>
      <p className="text-muted-foreground">{review.content}</p>
    </div>
  ))}
</div>
<Button variant='outline'>Show all Reviews</Button>
    </div>
    </div>
  );
}

