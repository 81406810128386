import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

export function LocationMap() {
  return (
    <div className='md:w-[1200px] '>
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Where you'll be</h2>
      <div className="md:aspect-[16/7] aspect-[16/9] overflow-hidden rounded-lg">
        <MapComponent/>
      </div>
      <p className="text-muted-foreground">
        Located in the Watergraafsmeer neighborhood, a peaceful residential area with easy access to
        public transport and local amenities.
      </p>
    </div>
  </div>
  );
}

const defaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = defaultIcon;

const MapComponent = () => {
  const position = [51.505, -0.09]; // Latitude, Longitude (London as an example)

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <MapContainer 
      //@ts-ignore
      center={position} zoom={13} style={{ height: "100%", width: "100%" }}>
        {/* Tile Layer using OpenStreetMap */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          //@ts-ignore
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Marker with Popup */}
        <Marker position={position}>
          <Popup>
            A marker in London. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};



