import React from 'react';
import { ImageGallery } from "../components/ImageGallery";
import { PropertyDetails } from "../components/PropertyDetails";
import { BookingWidget } from "../components/BookingWidget";
import { Reviews } from "../components/Reviews";
import { LocationMap } from "../components/LocationMap";
import { HostInfo } from "../components/HostInfo";
import { Amenities } from "../components/Amenities";
import { AvailabilityCalendar } from '../components/AvailabilityCalendar';
import MeetYourHost from '../components/MeetYourHost';


export default function ListingPage() {
  return (
    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <ImageGallery />
      <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-3">
        <div className="lg:col-span-2">
          <PropertyDetails />
          <hr className="my-8" />
          <Amenities />
          <hr className="my-8" />
          <AvailabilityCalendar />
          <hr className="my-8" />
          <Reviews />
          <hr className="my-8" />
          <LocationMap />
          <hr className="my-8" />
          <MeetYourHost />
        </div>
        <div className="lg:col-span-1">
          <BookingWidget />
        </div>
      </div>
    </main>
  );
}






