import React from 'react';
import { Calendar } from "./ui/calendar";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { addMonths } from 'date-fns';

export function AvailabilityCalendar() {
  const disabledDays = [
    new Date(2024, 0, 10),
    new Date(2024, 0, 15),
    new Date(2024, 0, 20),
    { from: new Date(2024, 1, 5), to: new Date(2024, 1, 15) }
  ];

  return (
    <div className="py-12">
      <h2 className="text-2xl font-semibold mb-8">Availability</h2>
      <Card>
        <CardHeader>
          <CardTitle>Select check-in date</CardTitle>
          <p className="text-sm text-muted-foreground">
            Add your travel dates for exact pricing
          </p>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col md:flex-row gap-4 overflow-x-auto pb-4 md:justify-evenly ">
            {[0, 1].map((offset) => (
              <div key={offset} className="min-w-[300px]">
                <Calendar
                  mode="single"
                  defaultMonth={addMonths(new Date(), offset)}
                  disabled={disabledDays}
                  className="rounded-md border"
                />
              </div>
            ))}
          </div>
          <p className='hover:underline cursor-pointer'>Clear Dates</p>
        </CardContent>
      </Card>
    </div>
  );
}

