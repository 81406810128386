'use client'

import { Bath, BedDouble, Car, Coffee, Dog, Dumbbell, Fan,  Gamepad2, Laptop, MountainIcon as Mountains, PocketIcon as Pool, Shirt, ShowerHead, Tv, UtensilsCrossed, Waves, Wifi, Wind } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog"
import { Button } from "./ui/button"
import { ScrollArea } from "./ui/scroll-area"
import { Separator } from "./ui/separator"

const amenities = {
  essentials: [
    { icon: Wifi, label: "Free WiFi" },
    { icon: Tv, label: "TV with Netflix" },
    { icon: Car, label: "Free parking" },
    { icon: Coffee, label: "Coffee maker" },
    { icon: UtensilsCrossed, label: "Fully equipped kitchen" },
    { icon: Wind, label: "Air conditioning" },
  ],
  views: [
    { icon: Mountains, label: "Mountain view" },
    { icon: Waves, label: "Lake view" },
  ],
  bathroom: [
    { icon: Bath, label: "Bathtub" },
    { icon: ShowerHead, label: "Rain shower" },
    { icon: Shirt, label: "Hangers" },
  ],
  entertainment: [
    { icon: Pool, label: "Private pool" },
    { icon: Dumbbell, label: "Fitness center" },
    { icon: Gamepad2, label: "Game room" },
  ],
  extras: [
    { icon: Dog, label: "Pets allowed" },
    { icon: Laptop, label: "Dedicated workspace" },
    { icon: Fan, label: "Ceiling fan" },
    { icon: BedDouble, label: "King size bed" },
  ],
}

export function AmenitiesDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
      <p  className='underline cursor-pointer'>Show More</p>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-2xl">What this place offers</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[60vh] pr-6">
          <div className="grid gap-8">
            <section>
              <h3 className="text-lg font-semibold mb-4">Essentials</h3>
              <div className="grid gap-4">
                {amenities.essentials.map((amenity) => (
                  <div key={amenity.label} className="flex items-center gap-4">
                    <amenity.icon className="w-6 h-6 text-muted-foreground" />
                    <span>{amenity.label}</span>
                  </div>
                ))}
              </div>
            </section>

            <Separator />

            <section>
              <h3 className="text-lg font-semibold mb-4">Scenic views</h3>
              <div className="grid gap-4">
                {amenities.views.map((amenity) => (
                  <div key={amenity.label} className="flex items-center gap-4">
                    <amenity.icon className="w-6 h-6 text-muted-foreground" />
                    <span>{amenity.label}</span>
                  </div>
                ))}
              </div>
            </section>

            <Separator />

            <section>
              <h3 className="text-lg font-semibold mb-4">Bathroom</h3>
              <div className="grid gap-4">
                {amenities.bathroom.map((amenity) => (
                  <div key={amenity.label} className="flex items-center gap-4">
                    <amenity.icon className="w-6 h-6 text-muted-foreground" />
                    <span>{amenity.label}</span>
                  </div>
                ))}
              </div>
            </section>

            <Separator />

            <section>
              <h3 className="text-lg font-semibold mb-4">Entertainment</h3>
              <div className="grid gap-4">
                {amenities.entertainment.map((amenity) => (
                  <div key={amenity.label} className="flex items-center gap-4">
                    <amenity.icon className="w-6 h-6 text-muted-foreground" />
                    <span>{amenity.label}</span>
                  </div>
                ))}
              </div>
            </section>

            <Separator />

            <section>
              <h3 className="text-lg font-semibold mb-4">Additional amenities</h3>
              <div className="grid gap-4">
                {amenities.extras.map((amenity) => (
                  <div key={amenity.label} className="flex items-center gap-4">
                    <amenity.icon className="w-6 h-6 text-muted-foreground" />
                    <span>{amenity.label}</span>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}

