import React from 'react';
import { Card, CardContent } from "./ui/card";
import { Badge } from "./ui/badge";
import { formatPrice } from "../lib/utils";

interface PaymentDetailsProps {
  basePrice: number;
  cleaningFee: number;
  nights: number;
  subtotal: number;
  serviceFee: number;
  total: number;
  monthlyPayment: number;
  savings: number;
}

export function PaymentDetails({
  basePrice,
  cleaningFee,
  nights,
  subtotal,
  serviceFee,
  total,
  monthlyPayment,
  savings
}: PaymentDetailsProps) {
  return (
    <Card>
      <CardContent className="p-6 space-y-6">
        <div>
          <h3 className="text-lg font-semibold">Monthly payments</h3>
          <p className="text-sm text-muted-foreground">
            Average {formatPrice(monthlyPayment)} before taxes and fees
          </p>
        </div>
        
        <div className="space-y-3">
          <div className="flex justify-between">
            <div className="font-medium">Due today</div>
            <div>{formatPrice(total)}</div>
          </div>
          <div className="flex justify-between text-sm text-muted-foreground">
            <div>Due in 30 days</div>
            <div>{formatPrice(monthlyPayment)}</div>
          </div>
        </div>

        <div className="rounded-lg bg-[#F5FFF9] p-4">
          <div className="flex items-start gap-2">
            <Badge variant="outline" className="bg-[#E3F5EB] border-0">
              You'll save {formatPrice(savings)}
            </Badge>
          </div>
          <p className="mt-2 text-sm text-muted-foreground">
            Savings include a weekly discount and a lower Airbnb service fee for staying longer.
          </p>
        </div>

        <button 
          className="text-sm text-primary underline w-full text-left"
          onClick={() => {/* Show details modal */}}
        >
          Show details
        </button>
      </CardContent>
    </Card>
  );
}

