import React from 'react';
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";

export function PolicySection() {
  return (
    <div className="space-y-8 py-8">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Cancellation policy</h2>
        <Card>
          <CardContent className="p-4">
            <p className="text-sm text-muted-foreground">
              Free cancellation before Jan 5. After that, the reservation is non-refundable.
            </p>
            <Button variant="link" className="h-auto p-0 text-sm">
              Learn more
            </Button>
          </CardContent>
        </Card>
      </div>

      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Ground rules</h2>
        <Card>
          <CardContent className="p-4">
            <p className="text-sm text-muted-foreground">
              We ask every guest to remember a few simple things about what makes a great guest.
            </p>
            <ul className="list-disc pl-4 mt-2 text-sm text-muted-foreground">
              <li>Follow the house rules</li>
              <li>Treat your Host's home like your own</li>
            </ul>
          </CardContent>
        </Card>
      </div>

      <div className="text-sm text-muted-foreground">
        By selecting the button below, I agree to the Host's House Rules, Ground rules for guests, 
        Airbnb's Rebooking and Refund Policy, and that Airbnb can charge my payment method if I'm 
        responsible for damage. Payment Terms between you and Airbnb Payments Luxembourg S.A.
      </div>

      <Button type="submit" className="w-full" size="lg">
        Confirm reservation
      </Button>
    </div>
  );
}

