import React, { useState } from "react";
import { Grid, Heart, Share } from 'lucide-react';
import { Button } from "./ui/button";
import { Dialog, DialogContent } from "./ui/dialog";

const images = [
  {
    src: "https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D",
    alt: "Living room with modern furniture",
    className: "w-[800px] h-[800px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://static.tacdn.com/img2/branding/homepage/hero-hotel-800x520.jpg",
    alt: "Kitchen view",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://hoteldel.com/wp-content/uploads/2021/01/hotel-del-coronado-views-suite-K1TOS1-K1TOJ1-1600x900-1.jpg",
    alt: "Bedroom with large windows",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.trvl-media.com/lodging/1000000/10000/3400/3313/0bb36e4a.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    alt: "Modern bathroom",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://plus.unsplash.com/premium_photo-1661876403473-64980cbfdf0f?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG90ZWwlMjBkZXNrfGVufDB8fDB8fHww",
    alt: "Study corner",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  }
];

export function ImageGallery() {
  const [showAllPhotos, setShowAllPhotos] = useState(false);

  return (
    <>
      <div className="relative mt-20">
        <div className="grid grid-cols-4 gap-2 rounded-xl overflow-hidden md:aspect-[16/6.5] aspect-[16/9]">
          <div className="col-span-2 row-span-2 relative">
            <img
              src={images[0].src}
              alt={images[0].alt}
              onClick={() => setShowAllPhotos(true)}
              className={images[0].className}
            />
          </div>
          {images.slice(1).map((image, i) => (
            <div key={i} className="relative">
              <img
                src={image.src}
                alt={image.alt}
                onClick={() => setShowAllPhotos(true)}
                className="object-cover w-full h-full cursor-pointer"
              />
            </div>
          ))}
        </div>

        <div className="absolute bottom-4 right-4 flex gap-2">
          <Button 
            variant="secondary" 
            className="bg-white hover:bg-white/90"
            onClick={() => setShowAllPhotos(true)}
          >
            <Grid className="mr-2 h-4 w-4" />
            Show all photos
          </Button>
        </div>

        <div className="absolute top-4 right-4 flex gap-2">
          <Button variant="secondary" className="bg-white hover:bg-white/90">
            <Share className="mr-2 h-4 w-4" />
            Share
          </Button>
          <Button variant="secondary" className="bg-white hover:bg-white/90">
            <Heart className="mr-2 h-4 w-4" />
            Save
          </Button>
        </div>
      </div>

      <Dialog open={showAllPhotos} onOpenChange={setShowAllPhotos}>
        <DialogContent className="max-w-7xl w-full h-[90vh] p-0">
          <div className="grid grid-cols-2 md:hidden gap-4 p-4 overflow-y-auto">
            {images.map((image, i) => (
              <div key={i} className="relative aspect-video">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="object-cover rounded-lg w-full h-full"
                />
              </div>
            ))}
          </div>
             <div className=" md:grid-cols-4 hidden md:grid gap-2 rounded-xl overflow-hidden md:aspect-[16/6.5] aspect-[16/9]">
          <div className="col-span-2 row-span-2 relative">
            <img
              src={images[0].src}
              alt={images[0].alt}
              onClick={() => setShowAllPhotos(true)}
              className={images[0].className}
            />
          </div>
          {images.slice(1).map((image, i) => (
            <div key={i} className="relative">
              <img
                src={image.src}
                alt={image.alt}
                onClick={() => setShowAllPhotos(true)}
                className="object-cover w-full h-full cursor-pointer"
              />
            </div>
          ))}
        </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
