import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Twitter } from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-gray-100 mt-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-600 uppercase tracking-wider">Support</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Help Center
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Safety information
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Cancellation options
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-600 uppercase tracking-wider">Community</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Airbnb.org: disaster relief housing
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Support Afghan refugees
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Combating discrimination
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-600 uppercase tracking-wider">Hosting</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Try hosting
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  AirCover: protection for Hosts
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Explore hosting resources
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-600 uppercase tracking-wider">About</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Newsroom
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Learn about new features
                </Link>
              </li>
              <li>
                <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                  Careers
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="flex space-x-6 md:order-2">
            <Link to="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Facebook</span>
              <Facebook className="h-6 w-6" />
            </Link>
            <Link to="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Instagram</span>
              <Instagram className="h-6 w-6" />
            </Link>
            <Link to="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Twitter</span>
              <Twitter className="h-6 w-6" />
            </Link>
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2024 Airbnb, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

