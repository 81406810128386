import React, { useState } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { Card, CardContent } from "./ui/card";
import { format } from 'date-fns';

interface ReservationFormProps {
  onSubmit: (formData: FormData) => void;
  isSubmitting: boolean;
  dates: { from: Date; to: Date };
  guests: string;
}

export function ReservationForm({ onSubmit, isSubmitting, dates, guests }: ReservationFormProps) {
  const [country, setCountry] = useState('Romania');

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      onSubmit(new FormData(e.currentTarget));
    }} className="space-y-8">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Your trip</h2>
        <Card>
          <CardContent className="p-4">
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Dates</div>
                <div className="text-sm text-muted-foreground">
                  {format(new Date(dates.from), 'MMM d, yyyy')} - {format(new Date(dates.to), 'MMM d, yyyy')}
                </div>
              </div>
              <Button variant="link" className="h-auto p-0">
                Edit
              </Button>
            </div>
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Guests</div>
                <div className="text-sm text-muted-foreground">{guests} {parseInt(guests) === 1 ? 'guest' : 'guests'}</div>
              </div>
              <Button variant="link" className="h-auto p-0">
                Edit
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="name">Name</Label>
          <Input id="name" name="name" required />
        </div>

        <h3 className="font-medium pt-4">Billing address</h3>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="street">Street address</Label>
            <Input id="street" name="street" required />
          </div>
          <div className="space-y-2">
            <Label htmlFor="apt">Apt or suite number</Label>
            <Input id="apt" name="apt" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="city">City</Label>
            <Input id="city" name="city" required />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="state">State</Label>
              <Input id="state" name="state" required />
            </div>
            <div className="space-y-2">
              <Label htmlFor="zip">ZIP code</Label>
              <Input id="zip" name="zip" required />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="country">Country/Region</Label>
            <Select value={country} onValueChange={setCountry}>
              <SelectTrigger id="country">
                <SelectValue placeholder="Select country" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Romania">Romania</SelectItem>
                <SelectItem value="Netherlands">Netherlands</SelectItem>
                <SelectItem value="Germany">Germany</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </form>
  );
}

