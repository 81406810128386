import React, { useEffect, useState } from 'react';
//@ts-ignore
import { Routes, Route } from 'react-router-dom';
import ListingPage from './pages/ListingPage';
import ConfirmReservationPage from './pages/ConfirmReservationPage';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Loader from './components/Loader';

function App() {

  const [loading,setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  },[])

  return (
    <>
    { loading ? <Loader/> : <>
    <div className="min-h-screen bg-white">
      <Header />
      <Routes>
        <Route path="/" element={<ListingPage />} />
        <Route path="/confirm-reservation" element={<ConfirmReservationPage />} />
      </Routes>
      <Footer />
    </div>
    </>
    }
    </>
  );
}

export default App;

