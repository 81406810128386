// import React, { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
// import { Star } from 'lucide-react';
// import { Button } from "./ui/button";
// import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
// import { DateRangePicker } from "./DateRangePicker";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "./ui/select";
// import { Separator } from "./ui/separator";
// import { formatPrice } from "../lib/utils";
// import { addDays, differenceInDays } from 'date-fns';

// export function BookingWidget() {
//   const navigate = useNavigate();
//   const [guests, setGuests] = useState("1");
//   const [dates, setDates] = useState({
//     from: new Date(),
//     to: addDays(new Date(), 5)
//   });
  
//   const basePrice = 156;
//   const cleaningFee = 50;
//   const serviceFeePercentage = 0.15;

//   const [total, setTotal] = useState({
//     nights: 5,
//     subtotal: 0,
//     serviceFee: 0,
//     total: 0,
//     monthlyPayment: 0,
//     savings: 0
//   });

//   useEffect(() => {
//     if (dates.from && dates.to) {
//       const nights = differenceInDays(dates.to, dates.from);
//       const subtotal = basePrice * nights;
//       const serviceFee = Math.round(subtotal * serviceFeePercentage);
//       const totalAmount = subtotal + serviceFee + cleaningFee;
//       const monthlyPayment = Math.round(totalAmount / 12);
//       const savings = Math.round(totalAmount * 0.05); // Assuming 5% savings for longer stays

//       setTotal({
//         nights,
//         subtotal,
//         serviceFee,
//         total: totalAmount,
//         monthlyPayment,
//         savings
//       });
//     }
//   }, [dates, guests]);

//   const handleReserve = () => {
//     navigate('/confirm-reservation', { 
//       state: { 
//         ...total, 
//         dates, 
//         guests, 
//         basePrice, 
//         cleaningFee 
//       } 
//     });
//   };

//   return (
//     <Card className="sticky top-24">
//       <CardHeader className="space-y-2">
//         <div className="flex items-baseline gap-2">
//           <span className="text-2xl font-bold">{formatPrice(basePrice)}</span>
//           <span className="text-muted-foreground">night</span>
//         </div>
//         <div className="flex items-center gap-2">
//           <div className="flex items-center gap-1">
//             <Star className="h-4 w-4" />
//             <span className="font-medium">4.9</span>
//           </div>
//           <span>·</span>
//           <Button variant="link" className="p-0 h-auto text-muted-foreground">
//             524 reviews
//           </Button>
//         </div>
//       </CardHeader>
//       <CardContent className="space-y-4">
//         <div className="grid grid-cols-1 gap-4">
//           <DateRangePicker 
//             value={dates}
//             //@ts-ignore
//             onChange={setDates}
//           />
//           <div className="space-y-2">
//             <label className="text-sm font-medium">Guests</label>
//             <Select value={guests} onValueChange={setGuests}>
//               <SelectTrigger>
//                 <SelectValue placeholder="Select number of guests" />
//               </SelectTrigger>
//               <SelectContent>
//                 {[1, 2, 3, 4].map((num) => (
//                   <SelectItem key={num} value={num.toString()}>
//                     {num} {num === 1 ? 'guest' : 'guests'}
//                   </SelectItem>
//                 ))}
//               </SelectContent>
//             </Select>
//           </div>
//         </div>

//         {total.nights > 0 && (
//           <div className="space-y-4 pt-4">
//             {/* <div className="flex justify-between">
//               <span className="underline">
//                 {formatPrice(basePrice)} x {total.nights} nights
//               </span>
//               <span>{formatPrice(total.subtotal)}</span>
//             </div> */}
//             {/* <div className="flex justify-between">
//               <span className="underline">Cleaning fee</span>
//               <span>{formatPrice(cleaningFee)}</span>
//             </div>
//             <div className="flex justify-between">
//               <span className="underline">Service fee</span>
//               <span>{formatPrice(total.serviceFee)}</span>
//             </div> */}
//             <Separator />
//             <div className="flex justify-between font-semibold">
//               <span>Total</span>
//               <span>{formatPrice(total.total)}</span>
//             </div>
//           </div>
//         )}
//       </CardContent>
//       <CardFooter>
//         <Button className="w-full" onClick={handleReserve}>Reserve</Button>
//       </CardFooter>
//     </Card>
//   );
// }







//second iteration

import { Diamond } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Star } from "lucide-react";
import { Button } from "./ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import { DateRangePicker } from "./DateRangePicker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Separator } from "./ui/separator";
import { formatPrice } from "../lib/utils";
import { addDays, differenceInDays } from "date-fns";

export function BookingWidget() {
  const navigate = useNavigate();
  const [guests, setGuests] = useState("2")
  const [dates, setDates] = useState({
    from: new Date(),
    to: addDays(new Date(), 3), // Default to 3 nights
  });
  const [isVisible, setIsVisible] = useState(true);

  const originalPrice = 101;
  const discountedPrice = 77; // Per night after discount

  const [total, setTotal] = useState({
    nights: 3,
    subtotal: 0,
    isRefundable: true,
  });

  useEffect(() => {
    if (dates.from && dates.to) {
      const nights = differenceInDays(dates.to, dates.from);
      const subtotal = discountedPrice * nights;

      setTotal((prevTotal) => ({
        ...prevTotal,
        nights,
        subtotal,
      }));
    }
  }, [dates]);

  useEffect(() => {
    const handleScroll = () => {
      // Adjust the value to match the desired scroll position
      const scrollThreshold = 2050; 
      setIsVisible(window.scrollY < scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleRateChange = (isRefundable) => {
    setTotal((prevTotal) => ({
      ...prevTotal,
      isRefundable,
    }));
  };

  const handleReserve = () => {
    navigate("/confirm-reservation", {
      state: {
        ...total,
        dates,
        guests,
        originalPrice,
        discountedPrice,
      },
    });
  };

  return (
   <>
    <Card 
    className={`sticky top-24 w-full max-w-md mx-auto shadow-lg transition-opacity duration-300${
      isVisible ? "md:opacity-100" : " md:hidden md:pointer-events-none "
    }`}>
      <CardHeader className="space-y-2">
        <div className="flex items-center gap-2 text-xl font-bold">
          <span className="line-through text-gray-500">
            {formatPrice(originalPrice)}
          </span>
          <span>{formatPrice(discountedPrice)}</span>
          <span className="text-sm text-muted-foreground">/ night</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <Star className="h-4 w-4 text-yellow-500" />
            <span className="font-medium">4.9</span>
          </div>
          <span>·</span>
          <Button variant="link" className="p-0 h-auto text-muted-foreground">
            524 reviews
          </Button>
        </div>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="grid gap-4">
          {/* Date Picker */}
          <DateRangePicker
            value={dates}
            //@ts-ignore
            onChange={setDates}
          />
          {/* Guests Selector */}
          <div className="space-y-2">
            <label className="text-sm font-medium">Guests</label>
            <Select value={guests} onValueChange={setGuests}>
              <SelectTrigger>
                <SelectValue placeholder="Select number of guests" />
              </SelectTrigger>
              <SelectContent>
                {[1, 2, 3, 4].map((num) => (
                  <SelectItem key={num} value={num.toString()}>
                    {num} {num === 1 ? "guest" : "guests"}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Rate Options */}
        <div className="space-y-4 pt-4">
          <div className="flex items-center justify-between">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="rate"
                checked={!total.isRefundable}
                onChange={() => handleRateChange(false)}
              />
              <span className="font-medium">Non-refundable</span>
            </label>
            <span className="text-sm">
              {formatPrice(total.subtotal)} total
            </span>
          </div>
          <div className="flex items-center justify-between">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="rate"
                checked={total.isRefundable}
                onChange={() => handleRateChange(true)}
              />
              <span className="font-medium">Refundable</span>
            </label>
            <span className="text-sm">
              {formatPrice(total.subtotal + 33)} total
            </span>
          </div>
          <p className="text-xs text-muted-foreground">
            {total.isRefundable
              ? "Free cancellation for 48 hours. Cancel before Jan 1 for a partial refund."
              : "Non-refundable rate with a lower price."}
          </p>
        </div>
      </CardContent>
      <CardFooter>
        <Button className="w-full" onClick={handleReserve}>
          Reserve
        </Button>
      </CardFooter>
      <RareFind/>
    </Card>
    
  </>
  );
}


function RareFind() {
  return (
    <div className="flex items-center mt-10 gap-4 p-4 border rounded-lg shadow-sm bg-white">
      <Diamond className="text-pink-500 h-6 w-6" />
      <div>
        <h4 className="text-lg font-semibold">This is a rare find</h4>
        <p className="text-sm text-gray-600">
          Rob & Fang's place is usually fully booked.
        </p>
      </div>
    </div>
  );
}



