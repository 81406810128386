import React from 'react';
import { Star } from 'lucide-react';
import { Badge } from "./ui/badge";
import { Card, CardContent } from "./ui/card";

export function PropertyPreview() {
  return (
    <Card>
      <CardContent className="p-4">
        <div className="flex gap-4">
          <div className="relative h-24 w-24 flex-shrink-0 overflow-hidden rounded-lg">
            <img
              src="/placeholder.svg?height=96&width=96"
              alt="AmstNest"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="flex-1">
            <h3 className="font-semibold">AmstNest - an urban retreat for two</h3>
            <p className="text-sm text-muted-foreground">Room in townhouse</p>
            <div className="mt-1 flex items-center gap-1">
              <Star className="h-4 w-4" />
              <span className="text-sm font-medium">4.90</span>
              <span className="text-sm text-muted-foreground">(558 reviews)</span>
              <Badge variant="secondary" className="ml-2">
                Superhost
              </Badge>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

