import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Textarea } from "./ui/textarea";
import { Card, CardContent } from "./ui/card";

export function MessageHost() {
  return (
    <div className="space-y-4 py-8">
      <h2 className="text-xl font-semibold">Message the host</h2>
      <p className="text-sm text-muted-foreground">
        Share why you're traveling, who's coming with you, and what you love about the space.
      </p>
      
      <Card>
        <CardContent className="p-4">
          <div className="flex gap-4 mb-4">
            <Avatar>
              <AvatarImage src="/placeholder.svg?height=40&width=40" alt="Rob & Fang" />
              <AvatarFallback>RF</AvatarFallback>
            </Avatar>
            <div className="flex-1">
              <div className="font-medium">Rob & Fang</div>
              <div className="text-sm text-muted-foreground">Joined in 2012</div>
            </div>
          </div>

          <div className="space-y-4">
            <p className="text-sm text-muted-foreground">
              Thanks for your interest in AmstNest!
              <br />
              We'll get in touch soon about public transportation etc. Feel free to contact us for any questions :)
              <br /><br />
              Greetings,
              <br />
              Rob and Fang
            </p>
            <Textarea
              placeholder="Hi Rob & Fang! I'll be visiting..."
              className="min-h-[100px]"
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

