import React from 'react';
import { Medal, Star, ChevronRight } from 'lucide-react';
import { Separator } from "./ui/separator";
import { Button } from "./ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Badge } from "./ui/badge";
import GuestFavoriteBadge from './GuestBadge';
import { X } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog"

const RoomInfo = {
    "Title": "Apartment in Watergraafsmeer",
    "Description": "1 Double Bed",
    "Description2": "Private Room Attached"
}

export function PropertyDetails() {
  return (
    <div className="space-y-6">
      <div className="flex flex-col justify-between items-start">
        <div>
          <h1 className="text-2xl font-semibold">{RoomInfo.Title}</h1>
          <div className="flex flex-col md:flex-row md:items-center items-start md:gap-4 gap-2 mt-2 text-sm">
            <div className="flex items-center gap-1">
              {/* <Star className="h-4 w-4" /> */}
              <span className="font-medium">{RoomInfo.Description}</span>
            </div>
            <span className='font-medium'>{RoomInfo.Description2}</span>
            {/* <Button variant="link" className="p-0 h-auto">524 reviews</Button> */}
            <span>·</span>
            {/* <Button variant="link" className="p-0 h-auto">Watergraafsmeer, Amsterdam, Netherlands</Button> */}
          </div>
        </div>
        <GuestFavoriteBadge/>
      </div>

      <div className="flex items-center gap-4 mb-5">
        <Avatar className="h-14 w-14">
          <AvatarImage src="/placeholder.svg?height=56&width=56" />
          <AvatarFallback>RF</AvatarFallback>
        </Avatar>
        <div>
          <h2 className="text-lg font-medium">Hosted by Rob & Fang</h2>
          <p className="text-sm text-muted-foreground">
            4 guests · 2 bedrooms · 2 beds · 2 baths
          </p>
        </div>
      </div>

      <Separator />

      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        <div className="flex gap-4">
          <Medal className="h-8 w-8" />
          <div>
            <h3 className="font-medium">Superhost</h3>
            <p className="text-sm text-muted-foreground">
              Superhosts are experienced, highly rated hosts.
            </p>
          </div>
        </div>
        <div className="flex gap-4">
          <Star className="h-8 w-8" />
          <div>
            <h3 className="font-medium">Great location</h3>
            <p className="text-sm text-muted-foreground">
              95% of recent guests gave the location a 5-star rating.
            </p>
          </div>
        </div>
        <div className="flex gap-4">
          <Star className="h-8 w-8" />
          <div>
            <h3 className="font-medium">Great location</h3>
            <p className="text-sm text-muted-foreground">
              95% of recent guests gave the location a 5-star rating.
            </p>
          </div>
        </div>
      </div>

      <Separator />

      <div className="prose prose-sm max-w-none">
        <p className='text-[25px] font-semibold mb-5'>About This Place</p>
        <p>
          Located in a beautiful part of the city, this apartment offers the perfect blend of comfort and style. Perfect for couples and families looking for a quiet, comfortable stay with easy access to public transport and local amenities.
        </p>
        <p className="mt-4">
          The space features modern amenities, a fully equipped kitchen, and a cozy living area. Large windows provide plenty of natural light and beautiful views of the neighborhood.
        </p>
      </div>
      <AboutSpaceDialog/>
    </div>
  );
}

 function AboutSpaceDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
      <Button variant="link" className="p-0">
        Show more
        <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl h-[400px] md:h-auto overflow-scroll">
        <DialogHeader>
          <div className="flex items-start">
            <DialogTitle className="text-2xl font-semibold">About this space</DialogTitle>
          </div>
        </DialogHeader>
        <div className="space-y-6">
          <p className="text-base leading-relaxed">
            Nestled in the lush uplands of Nashik, Cascading Valleys is a tranquil villa that gifts splendid views
            just like its name. As one heads to the villa with its stone-laden steps, you get a glimpse at the villa
            and the majestic mountains in its backdrop. Enter the villa and you sight the shimmering swimming
            pool that also has a seating area. The bedrooms are coated in soothing tones, with two of them
            having balconies that provide sweeping vistas of Nashik.
          </p>

          <div>
            <h3 className="text-xl font-semibold mb-2">The space</h3>
            <p className="text-base leading-relaxed">
              Dotted with many open spaces, the terrace and the balcony are ideal for sitting and enjoying
              peaceful views. Located close to Alandi Dam, the villa is perfect for people planning a cosy picnic in
              Nashik with their loved ones. Cascading Valleys is a quaint villa in Nashik that provides an
              adventurous yet jovial stay.
            </p>
          </div>

          <p className="text-base leading-relaxed">
            For a comfortable and secure stay, please take a moment to read the Home Truths, House Rules
            and Policies thoroughly.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  )
}



